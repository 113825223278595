import authCardBg from './images/auth-card-bg.jpg'
import contentCardRightSide from './images/content-card-right-side.jpg'
import loadingDesktopBg from './images/loading-desktop-bg.jpg'
import landingMainBg from './images/landing-main-bg.jpg'

import { ProductName } from '../../types/interface/quote.interface'
import {
  ThemeBrandAssetsType,
  ThemeBrandColorsType,
  ThemeBrandTypographyType,
} from 'BrandTheme/types'
import brandTheme from '../theme/utils'
import logoDark from './images/logoDark.png'

const initCallback = () => {
  const productName: ProductName = ProductName.VEHICLE
  const collisionMobileBackground = authCardBg

  const brandColors: ThemeBrandColorsType = {
    main: '#000000',
    secondary: '#000000',
    background: '#FAFAFA',
    headingMain: '#000000',
    headingSecondary: '#000000',
    textMain: '#000000',
    textSecondary: '#768692',
    textError: '#F84971',
    textPlaceholder: '#C8CFD3',
    textDisclaimer: '#C8CFD3',
    textInactive: '#C8CFD3',
    buttonActive: '#000000',
    buttonInactive: '#d5d5d5',
    inputActive: '#000000',
    inputInactive: '#333F48',
    linkMain: '#000000',
    linkSecondary: '#000000',
    modal: '#C4C4C4',
    shadow: '#000000',
    clean: '#FFFFFF',
    cardHeader: '#000',
    textCardHeader: '#FFF',
    textCardFooter: '#000000',
  }

  const productBackgroundsMap = {
    [ProductName.VEHICLE]: {
      loadingMobile: `linear-gradient(to bottom, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0) 20%), 
      url(${loadingDesktopBg}) no-repeat center bottom`,
      loading: `url(${loadingDesktopBg}) no-repeat center center fixed`,
      authDesktop: brandColors.main,
      authMobileBg: `url(${authCardBg}) no-repeat left top scroll`,
      reviewBg: '',
    },
  }

  const loadingBackground = productBackgroundsMap[productName].loading
  const loadingMobileBackground = productBackgroundsMap[productName].loadingMobile

  const mainBackgroung = productBackgroundsMap[productName].authDesktop
  const mainBackgroungMobile = productBackgroundsMap[productName].authMobileBg

  const reviewBackground = productBackgroundsMap[productName].reviewBg

  const brandTypography: ThemeBrandTypographyType = {
    heading: {
      h1: {
        fontSize: '1.75rem', // 28px
        fontWeight: 500,
        color: brandColors.headingMain,
        lineHeight: 1.4,
      },
      h2: {
        fontSize: '1.5rem', // 24px
        fontWeight: 500,
        color: brandColors.headingMain,
        lineHeight: 1.4,
      },
      h3: {
        fontSize: '1.25rem', // 20px
        fontWeight: 400,
        color: brandColors.headingSecondary,
        lineHeight: 1.4,
      },
      h4: {
        fontSize: '1rem', // 16px
        fontWeight: 500,
        color: brandColors.headingMain,
        lineHeight: 1.4,
      },
      h5: {
        fontSize: '0.75rem', // 12px
        fontWeight: 400,
        color: brandColors.textMain,
        lineHeight: 1.4,
      },
      h6: {
        fontSize: '0.75rem', // 12px
        fontWeight: 400,
        color: brandColors.headingMain,
        lineHeight: 1.4,
      },
    },
    text: {
      xSmall: {
        fontSize: '0.688rem', // 11px
        lineHeight: 1.4,
      },
      small: {
        fontSize: '0.75rem', // 12px
        lineHeight: 1.4,
      },
      normal: {
        fontSize: '1rem', // 16px
        lineHeight: 1.4,
      },
      large: {
        fontSize: '1.5rem', // 24px
        lineHeight: 1.4,
      },
      xLarge: {
        fontSize: '2.5rem', // 40px
        lineHeight: 1.4,
      },
    },
    modifiers: {
      bold: {
        fontWeight: 700,
      },
      decorated: {
        textDecoration: 'underline',
      },
      unDecorated: {
        textDecoration: 'none',
        '& *': {
          textDecoration: 'none',
        },
      },
      withHover: {
        '&:hover': {
          opacity: 0.75,
          cursor: 'pointer',
        },
      },
      preFormatted: {
        whiteSpace: 'pre',
      },
    },
    overrides: {
      contactCustomerSwitchText: {
        color: brandColors.textSecondary,
      },
      landingTitle: {
        color: `${brandColors.clean} !important`,
      },
      authTitle: {
        fontSize: '26px !important',
      },
    },
    extraFonts: [],
  }

  const theme = brandTheme.setup({
    productName,
    colors: brandColors,
    typography: brandTypography,
  }).theme

  const overrides = {
    contained: {
      '&:hover': {
        color: brandColors.clean,
        opacity: 0.75,
        '& .MuiSvgIcon-root': {
          color: brandColors.clean,
        },
      },
      '&:disabled': {
        backgroundColor: brandColors.buttonInactive,
        color: brandColors.textInactive,
      },
    },
    MuiSwitch: {
      root: {
        width: 74,
        height: 48,
        overflow: 'visible',
        '& .MuiSwitch-track': {
          width: 70,
          borderRadius: 12,
        },
        '& .MuiSwitch-input': {
          width: '200%',
        },
        '& .MuiSwitch-thumb': {
          width: 18,
          height: 18,
        },
        '& .MuiSwitch-switchBase': {
          top: 6,
          left: 6,
          '& .MuiIconButton-label': {
            color: brandColors.clean,
          },
          '&.Mui-checked': {
            transform: 'translateX(30px)',
            left: 2,
            '&.Mui-disabled': {
              '& + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
            '& .MuiSwitch-thumb': {
              backgroundColor: brandColors.clean,
            },
            '& + .MuiSwitch-track': {
              backgroundColor: brandColors.linkSecondary,
              opacity: 1,
            },
          },
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: brandColors.textMain,
      },
    },
    MuiButton: {
      root: {
        height: 48,
        borderRadius: '0',
        '&:hover': {
          color: brandColors.clean,
          opacity: 0.75,
          '& .MuiSvgIcon-root': {
            color: brandColors.clean,
          },
        },
      },
      outlined: {
        '&:hover': {
          backgroundColor: brandColors.main,
          color: brandColors.clean,
        },
        '&:active': {
          backgroundColor: brandColors.main,
          color: brandColors.clean,
        },
        '@media (hover:none)': {
          '&:hover': {
            opacity: 1,
            color: brandColors.main,
            backgroundColor: brandColors.clean,
          },
          '&:hover svg': {
            fill: brandColors.main,
          },
          '&:active': {
            backgroundColor: brandColors.main,
            color: brandColors.clean,
          },
          '&:active svg': {
            fill: brandColors.clean,
          },
        },
      },
      contained: {
        height: 48,
        '&:hover': {
          color: brandColors.clean,
          backgroundColor: `${brandColors.buttonActive} !important`,
          opacity: 0.7,
          '& .MuiSvgIcon-root': {
            color: brandColors.clean,
          },
        },
        '&:active': {
          backgroundColor: `${brandColors.buttonActive} !important`,
          opacity: 0.7,
        },
        '@media (hover:none)': {
          '&:hover': {
            opacity: '1 !important',
          },
        },
        '&:disabled': {
          backgroundColor: brandColors.buttonInactive,
          color: brandColors.clean,
        },
      },
    },
  }

  const brandAssets: ThemeBrandAssetsType = {
    pageBackgrounds: {
      main: {
        background: mainBackgroung,
        backgroundSize: 'cover',
      },
      mainMobile: {
        background: mainBackgroungMobile,
        backgroundSize: 'cover',
        backgroundPosition: 'top',
      },
      mainCard: {
        background: `url(${authCardBg}) no-repeat left top scroll`,
        backgroundSize: 'cover',
        backgroundPositionX: 'center',
      },
      secondary: {
        background: brandColors.main,
        backgroundSize: 'cover',
      },
      secondaryMobile: {
        background: `url(${authCardBg}) no-repeat left top scroll`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      secondaryCard: {
        background: `url(${authCardBg}) no-repeat center center`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      error: {
        background: mainBackgroung,
        backgroundSize: 'cover',
      },
      errorCardMain: {
        background: `url(${authCardBg}) no-repeat left top scroll`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      errorCardMobileMain: {
        background: `url(${collisionMobileBackground}) no-repeat left top scroll`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      loading: {
        alignContent: 'center',
        background: loadingBackground,
        backgroundSize: 'cover',
      },
      loadingMobile: {
        background: loadingMobileBackground,
        backgroundSize: 'cover',
      },
      review: {
        background: reviewBackground,
        backgroundSize: 'contain',
      },
      verify: {
        background: reviewBackground,
        backgroundSize: 'contain',
      },
    },
    images: {
      changeVehicleOwnerPageCard: {
        background: `url(${contentCardRightSide}) no-repeat right top scroll`,
        backgroundSize: '50%, auto, cover',
        backgroundPosition: 'right',
        backgroundPositionY: '60%',
      },
      logoDesktopHeader: {
        background: `url(${logoDark}) no-repeat left center scroll`,
        backgroundSize: 275,
        minWidth: 275,
        display: 'flex',
        flex: 1,
      },
      logoMobileHeader: {
        background: `url(${logoDark}) no-repeat left center scroll`,
        backgroundSize: 'contain',
        height: 60,
      },
      logoMax: {
        width: 309,
        margin: '40px 10px',
        alignSelf: 'start',
      },
      landingMainBg: {
        background: `url(${landingMainBg}) no-repeat right bottom scroll`,
      },
    },
    layout: {
      card: {
        cursor: 'pointer',
        '&:hover p, &:visited p, &:active p': {
          color: brandColors.clean,
        },
        '&:hover, &:visited': {
          backgroundColor: brandColors.main,
        },
        '&:active': {
          backgroundColor: brandColors.main,
          opacity: 0.7,
        },
      },
      mobileButton: {
        borderRadius: 0,
        '& p': {
          color: brandColors.clean,
        },
        '&:hover': {
          opacity: '.75',
          background: brandColors.main,
        },
      },
      mobileCustomTitle: {
        fontSize: '20px',
        marginBottom: 8,
        color: brandColors.headingMain,
      },
      mobileCustomHeadingText: {
        marginTop: 'auto',
        color: brandColors.clean,
      },
      mobileCustomSubTitle: {
        color: brandColors.headingMain,
        fontSize: 12,
      },
      LMBgHeaderHeader: {
        [theme.breakpoints.down('sm')]: {
          backgroundPositionY: 'bottom',
          height: 300,
        },
      },
      loadingTitle: {
        color: `${brandColors.clean} !important`,
      },
      loadingTitleMobile: {
        marginTop: '14px',
        color: `${brandColors.headingMain} !important`,
      },
      btnNext: {
        border: `1px solid ${brandColors.buttonActive}`,
        color: brandColors.textMain,
      },
      reviewRightColItem: {
        color: brandColors.main,
      },
      customHeaderTextWrapper: {
        background: brandColors.clean,
        padding: '16px 16px 0',
        zIndex: 10,
        marginTop: '-61px',
      },
      buttonLink: {
        background: 'none',
        maxWidth: 'fit-content',
        boxShadow: 'none',
        color: brandColors.shadow,
        textTransform: 'capitalize',
        fontSize: 12,
        padding: 0,
        height: 'auto',
        textDecoration: 'underline',
        marginBottom: 4,
        fontWeight: 400,
        '&:hover': {
          backgroundColor: 'transparent !important',
          color: brandColors.shadow,
          boxShadow: 'none',
          textDecoration: 'underline',
        },
      },
      customMobileSubmit: {
        [theme.breakpoints.down('sm')]: {
          position: 'absolute',
          bottom: 0,
        },
      },
    },
  }
  return brandTheme.setup({
    productName,
    colors: brandColors,
    typography: brandTypography,
    assets: brandAssets,
    overrides,
  })
}

export default brandTheme.init(initCallback)
