import QuoteAndBuyFormConfig from './qb/config'
import MyPagesFormConfig from './mypages/config'
import { BrandFormConfigInterface } from '../../../form/shared/mypages/config'
import { FlowName } from '../../../config'

const FormConfig: BrandFormConfigInterface = {
  [FlowName.QUOTE_AND_BUY]: QuoteAndBuyFormConfig,
  [FlowName.MY_PAGES]: MyPagesFormConfig,
}

export default FormConfig
