import React, { FC } from 'react'
import { FSMBtnNext, ReCaptchaPrivacyTerms, Typography } from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { TRANSLATIONS as T } from '../../../constants/translations-constants'
import { getDataComponentName, getLocalizedPartnerName } from '@dg-util'
import { FSMService, FSMMatch, FSMSwitch } from '../../../FSM'
import BrandConfig from 'BrandConfig'
import { Events, MATCH_COMPOUND } from '../../../FSM/shared/constants'
import { AUTH_ERROR_TRANSLATION_KEYS } from '../../../constants'
import { has } from 'lodash'
import { FSMStateType } from '../../../types/interface/fsm.interface'

export const useAuthDisclaimerText = makeStyles(
  (theme: Theme) =>
    createStyles({
      paragraph: {
        marginBottom: theme.spacing(1),
        textAlign: 'start',
        [theme.breakpoints.down('sm')]: {
          textAlign: 'center',
        },
      },
      error: {
        marginBottom: theme.spacing(1),
      },
      link: {
        ...theme.brand.assets.layout?.buttonLink,
      },
      disclaimer: {
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
          textAlign: 'center',
          ...theme.brand.assets.layout?.authDisclaimer,
        },
        '& a': {
          textDecoration: 'underline',
          color: 'inherit',
        },
      },
    }),
  { index: 1 }
)

type AuthDisclaimerText = {
  errorEventType: string
  fsmState?: FSMStateType
}

const MMGAuthStrategyDisclaimer: FC<AuthDisclaimerText> = ({ errorEventType, fsmState }) => {
  const classes = useAuthDisclaimerText()
  const { t } = useTranslate(T.AUTH)

  const errorMessage = has(AUTH_ERROR_TRANSLATION_KEYS, errorEventType) && (
    <Typography
      testId='auth-strategy-error-message'
      color='textError'
      size={'small'}
      modifiers={['bold']}
      className={classes.error}
    >
      {t(AUTH_ERROR_TRANSLATION_KEYS[errorEventType])}
    </Typography>
  )

  const partnerName = getLocalizedPartnerName()

  return (
    <div data-componentname={getDataComponentName(__filename)}>
      <FSMSwitch>
        <FSMMatch is={MATCH_COMPOUND.AUTH_CHOICE}>
          <Typography color='textMain' size='small' className={classes.disclaimer}>
            {`${t('disclaimer.0')}`}
            <FSMBtnNext
              buttonText={t('disclaimer.1', { partnerName })}
              handleClick={() => {
                FSMService.flowService.send({ type: Events.LANDING_PAGE, state: fsmState })
              }}
              customClassName={classes.link}
            />
            {`${t('disclaimer.2')}`}
          </Typography>
          {errorMessage}
        </FSMMatch>
        <FSMMatch is={MATCH_COMPOUND.AUTH_REGULAR}>
          {errorMessage}
          <ReCaptchaPrivacyTerms
            customClassName={`${classes.paragraph}`}
            links={BrandConfig.GOOGLE_RECAPTCHA_LINKS}
          />
        </FSMMatch>
        <FSMMatch is={MATCH_COMPOUND.AUTH_AGENT}>
          {errorMessage}
          <ReCaptchaPrivacyTerms
            customClassName={`${classes.paragraph}`}
            links={BrandConfig.GOOGLE_RECAPTCHA_LINKS}
          />
        </FSMMatch>
      </FSMSwitch>
    </div>
  )
}

export default MMGAuthStrategyDisclaimer
